<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Добавление категории</div>
                <div class="page__desc">Введите данные новой категории</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="categories !== null" class="form-group" method="POST" action="" @submit.prevent="create()">

                <label for="title">Название</label>
                <div class="control-group">
                    <input
                            v-model="title"
                            id="title"
                            type="text"
                            name="title"
                            class="form-control"
                            required>
                </div>
                <br>

                <label for="category_id">Категория</label>
                <div class="control-group">
                    <select
                            v-model="parent_id"
                            id="category_id"
                            name="category_id"
                            class="form-control"
                            required>
                        <option
                                v-bind:key="0"
                                value="0" selected>-</option>
                        <option
                                v-for="c in categories"
                                v-bind:key="c.id"
                                :value="c.id"
                                :selected="parent_id === c.id">{{ c.title }}</option>
                    </select>
                </div>
                <br>

                <label for="photo">Фото</label><br>
                <div class="control-group">
                    <input ref="photo" id="photo" type="file" name="photo" class="form-control">
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CatalogCategoryCreate',
        data() {
            return {
                parent_id: this.$route.params.parent_id == null ? 0 : this.$route.params.parent_id,
                title: ""
            }
        },
        computed: {
            categories() {
                return this.$store.state.catalog.categories
            }
        },
        methods: {
            ...mapActions([
                'catalogCategories',
                'catalogCategoryCreate',
            ]),
            async getCategories() {
                await this.catalogCategories({
                    id: null
                })
            },
            async create() {
                const formData = new FormData();
                formData.append("photo", this.$refs.photo.files[0]);
                formData.append("title", this.title);
                formData.append("parent_id", this.parent_id);

                await this.catalogCategoryCreate(formData)
            }
        },
        created() {
            this.getCategories();
        },
    }
</script>